import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import styled from '@emotion/styled';
import Spinner from 'react-spinkit';
import ReactProgressiveList from "react-progressive-list";
import { GalleryItem } from "../api";
import Modal from "./Modal";
import "../assets/style/gallery-container.css";

const ASSETS_IMAGE_BACKGROUND_TILE_DARK = require('../assets/images/assets_images_background_tile_dark.png');
const ASSETS_IMAGE_WOLVESVILLE_ICON = require('../assets/images/assets_images_wolvesville_icon.png');

class GalleryComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      filter: "",
      activeItem: undefined,
    }
  }

  _renderItem = (index) => {
    const { galleryItems } = this.props;
    const { filter } = this.state;

    const galleryItem = galleryItems.filter(item => filter.length <= 0 ? true : (item.author.toLowerCase().includes(filter.toLowerCase())))[index];

    if (galleryItem) {
      return (
        <GridItem>
          <CardContainer onClick={() => this.setState({ activeItem: galleryItem })}>
            <CardCoverImage src={galleryItem.itemUri} />
            <CardDetailsContainer>
              <CardDetailsRow>
                <CardDetailsRowTitle>{galleryItem.author}</CardDetailsRowTitle>
                {galleryItem.creationTime && <CardDetailsRowSubtitle>{galleryItem.creationTime}</CardDetailsRowSubtitle>}
              </CardDetailsRow>
              <CardDetailsRow>
                <CardDetailsRowTitle style={{ textAlign: 'right' }}>{galleryItem.rating}&nbsp;★</CardDetailsRowTitle>
                <CardDetailsRowSubtitle>{galleryItem.votingCount} votes</CardDetailsRowSubtitle>
              </CardDetailsRow>
            </CardDetailsContainer>
          </CardContainer>
        </GridItem>
      );
    }
  };

  render() {
    const { filter, activeItem } = this.state;
    const { galleryItems } = this.props;

    return (
      <div style={{ flex: 1 }}>
        {activeItem && (
          <Modal item={activeItem} onClose={() => this.setState({ activeItem: undefined })} />
        )}

        <Fade>
          <Header>
            <HeaderContentContainer>
              <Logo src={ASSETS_IMAGE_WOLVESVILLE_ICON} />
              <HeadlineContainer>
                <Title>Wolvesville</Title>
                <Subtitle>Voting Gallery</Subtitle>
              </HeadlineContainer>
            </HeaderContentContainer>
            <Input type="text" placeholder="Search" value={filter} onChange={e => this.setState({ filter: e.target.value })} />
          </Header>
          <div>
            {galleryItems && (
              <ReactProgressiveList
                className="gallery-container"
                initialAmount={20}
                progressiveAmount={20}
                renderItem={this._renderItem}
                renderLoader={() => <center style={{ marginBottom: 40, width: '100%', marginTop: 40 }}><Spinner color="#FFF" /></center>}
                rowCount={galleryItems ? galleryItems.length : 0}
                useWindowScroll
              />
            )}
          </div>
        </Fade>

        {!galleryItems && <center style={{ marginBottom: 40 }}><Spinner color="#FFF" /></center>}

        <Footer>
          <Imprint>
            <center>
              <a style={{ color: '#FFF' }} rel="noopener noreferrer" target="_blank" href="https://github.com/lucienbl">Made by Lucien</a><br /><br />
              <Link rel="noopener noreferrer" target="_blank" href="https://legal.wolvesville.com/imprint.html">Imprint&nbsp;&nbsp;•&nbsp;&nbsp;</Link>
              <Link rel="noopener noreferrer" target="_blank" href="https://legal.wolvesville.com/privacy-policy.html">Privacy Policy&nbsp;&nbsp;•&nbsp;&nbsp;</Link>
              <Link rel="noopener noreferrer" target="_blank" href="https://legal.wolvesville.com/tos.html">Terms of Service&nbsp;&nbsp;•&nbsp;&nbsp;</Link>
            </center>
          </Imprint>
        </Footer>

        <Background />
      </div>
    );
  }
}

const Imprint = styled.div`
`;

const Link = styled.a`
  text-decoration: none;
  text-align: center;
  color: #EEE;
  font-size: 13px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 51px;
  margin-left: 88px;
  justify-content: space-between;
  
  @media only screen and (max-width: 700px) {
     margin-left: 2%;
     display: block;
  }
`;

const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
`;

const Title = styled.span`
  color: white;
  font-weight: 700;
  font-size: 48px;
  
  @media only screen and (max-width: 700px) {
    font-size: 30px;
  }
`;

const Subtitle = styled.span`
  color: white;
  font-weight: 400;
  font-size: 33px;
  
  @media only screen and (max-width: 700px) {
    font-size: 20px;
  }
`;

const Logo = styled.img`  
  width: 117px;
  height: 117px;
  border-radius: 100%;
  
  @media only screen and (max-width: 700px) {
     width: 100px;
     height: 100px;
  }
`;

const Input = styled.input`
  width: 20%;
  background-color: #FFFFFF00;
  padding-left: 20px;
  font-size: 18px;
  color: #AAA;
  margin-top: 20px;
  border: solid 2px #888;
  border-radius: 5px;
  height: 40px;
  margin-right: 88px;
  
  &:focus {
    border-color: #fc226e;
    outline: none;
  }
  
  ::placeholder {
    font-size: 18px;
    color: #888;
  }
  
  @media only screen and (max-width: 700px) {
     margin: 30;
     width: 90%;
  }
`;

const CardCoverImage = styled.img`
  height: 180px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0; 
  margin-bottom: 16px;
`;

const GridItem = styled.div`
  min-width: 10%;
  flex-basis: 20%;
  -ms-flex: auto;
  width: 300px;
  position: relative;
  box-sizing: border-box;
  padding: 16px;
  
  @media only screen and (max-width: 1333px) {
    flex-basis: 25%;
  }
  @media only screen and (max-width: 1073px) {
    flex-basis: 33.33%;
  }
  @media only screen and (max-width: 815px) {
    flex-basis: 50%;
  }
  @media only screen and (max-width: 555px) {
    flex-basis: 100%;
  }
`;

const CardContainer = styled.div`
  background-color: #41454B;
  border-radius: 10px;
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  cursor: pointer;
`;

const CardDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  alignItems: center;
`;

const Footer = styled.div`
  width: 100%;
  height: 40%; 
  margin-bottom: 20px;
`;

const Background = styled.div`
  width: 100%;
  height: 100%; 
  position: absolute; 
  top: 0px;
  bottom: 0px;
  right: 0px; 
  left: 0px;
  z-index: -1;
  background-image: url("${ASSETS_IMAGE_BACKGROUND_TILE_DARK}");
  background-repeat: repeat;
  background-size: 25% auto;
  position: fixed;
  
  @media only screen and (max-width: 500px) {
     background-size: 100% auto;
  }
`;

const CardDetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  alignItems: center;
  justifyContent: center;
  margin-left: 16px;
  margin-right: 16px;
`;

const CardDetailsRowTitle = styled.span`
  color: white;
  font-weight: 600;
  font-size: 18px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const CardDetailsRowSubtitle = styled.span`
  color: white;
  font-weight: 300;
  font-size: 14px;
  word-break: break-all;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

GalleryComponent.propTypes = {
  galleryItems: PropTypes.arrayOf(GalleryItem),
};

export default GalleryComponent;
