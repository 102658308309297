import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from "./store";
import { GalleryScreen } from "./gallery";

const initialState = window.__INITIAL_STATE__;
const store = createStore(initialState);

function App() {
  return (
    <Provider store={store}>
      <GalleryScreen />
    </Provider>
  );
}

export default App;
