import CoreApiClient from './CoreApiClient';
import GalleryItem from './GalleryItem';

const logCatch = (e: Object) => {
  console.error(e);
  console.warn('Wrong Server?');
};

export {
  CoreApiClient,
  logCatch,
  GalleryItem
};
