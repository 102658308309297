import { handleActions } from 'redux-actions';
import * as Actions from './actionCreators';
import * as storeKeys from './storeKeys';

const initialState = {
  [storeKeys.KEY_GALLERY_ITEMS]: undefined,
};

export default handleActions({
  [Actions.SET_GALLERY_ITEMS]: (state, action) => ({
    ...state,
    [storeKeys.KEY_GALLERY_ITEMS]: action.payload.galleryItems,
  }),
}, initialState);
