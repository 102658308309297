import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import styled from '@emotion/styled';
import { GalleryItem } from "../api";

class Modal extends React.Component {

  render() {
    const { item, onClose } = this.props;

    return (
      <ModalWrapper onClick={onClose}>
        <Fade>
          <CardContainer>
            <CardCoverImage src={item.itemUri} />
            <CloseButton onClick={onClose}>X</CloseButton>
            <CardDetailsContainer>
              <CardDetailsRow>
                <CardDetailsRowTitle>{item.author}</CardDetailsRowTitle>
                <CardDetailsRowSubtitle>{item.creationTime}</CardDetailsRowSubtitle>
              </CardDetailsRow>
              <CardDetailsRow>
                <CardDetailsRowTitle style={{ textAlign: 'right' }}>{item.rating}&nbsp;★</CardDetailsRowTitle>
                <CardDetailsRowSubtitle>{item.votingCount} votings</CardDetailsRowSubtitle>
              </CardDetailsRow>
            </CardDetailsContainer>
          </CardContainer>
        </Fade>
      </ModalWrapper>
    );
  }
}

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000000AA;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  position: fixed;
  display: flex;
  justify-content: center;
`;

const CardContainer = styled.div`
  background-color: #41454B;
  border-radius: 10px;
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  flex-direction: column;
  margin-top: 10%;
  position: relative;
  width: 100%;
  
  @media only screen and (max-width: 700px) {
    margin-right: 2%;
    margin-left: 2%;
    width: 95%;
  }
`;


const CardCoverImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 10px 10px 0 0; 
  margin-bottom: 20px;
`;

const CardDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CardDetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 22px;
  margin-right: 22px;
`;

const CardDetailsRowTitle = styled.span`
  color: white;
  font-weight: 600;
  font-size: 18px;
`;

const CardDetailsRowSubtitle = styled.span`
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 20px;
`;

const CloseButton = styled.span`
  font-size: 30px;
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
`;

Modal.propTypes = {
  item: PropTypes.instanceOf(GalleryItem).isRequired,
  onClose: PropTypes.func.isRequired
};

export default Modal;
