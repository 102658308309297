import config from '../config';
import superagent from '../utils/superagent';
import { GalleryItem } from "./index";

class CoreApiClient {

  static async galleryItems(): Promise<Object> {
    return superagent
      .get(`${this._baseUrl()}/votingGallery/v2/history`)
      .then(res => res.body.map(galleryItem => new GalleryItem(galleryItem)))
      .catch(err => err.body);
  }

  static _baseUrl(): string {
    return config.coreApi.baseUrlProd;
  }
}

export default CoreApiClient;
