class GalleryItem {

  _id: string;
  _author: string;
  _itemUri: string;
  _status: string;
  _note: string;
  _votingCount: number;
  _rating: number;
  _creationTime: string;

  constructor({ id, author, itemUri, status, note, votingCount, rating, creationTime }) {
    this._id = id;
    this._author = author;
    this._itemUri = itemUri;
    this._status = status;
    this._note = note;
    this._votingCount = votingCount;
    this._rating = rating;
    this._creationTime = creationTime;
  }

  get id() {
    return this._id;
  }

  get author() {
    return this._author;
  }

  get itemUri() {
    return this._itemUri;
  }

  get status() {
    return this._status;
  }

  get note() {
    return this._note;
  }

  get votingCount() {
    return this._votingCount;
  }

  get rating() {
    return this._rating.toFixed(2);
  }

  get creationTime() {
    return this._creationTime ? new Date(this._creationTime).toLocaleDateString() : undefined;
  }
}

export default GalleryItem;
