import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GalleryComponent from './GalleryComponent';
import * as actionCreators from './actionCreators';
import * as selectors from './selectors';
import { GalleryItem } from '../api';

const mapStateToProps = state => ({
  galleryItems: selectors.galleryItems(state),
});

class GalleryContainer extends React.Component {

  async componentDidMount(): void {
    const { dispatch } = this.props;
    await dispatch(actionCreators.galleryItems());
  }

  render() {
    const { galleryItems } = this.props;

    return (
      <GalleryComponent
        galleryItems={galleryItems}
      />
    );
  }

}

GalleryContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  galleryItems: PropTypes.arrayOf(GalleryItem)
};

export default connect(mapStateToProps)(GalleryContainer);
