import { createAction } from 'redux-actions';
import { CoreApiClient, logCatch } from '../api';

export const SET_GALLERY_ITEMS = 'SET_GALLERY_ITEMS';

export const galleryItems = () =>
  async (dispatch: any) => {
    try {
      const galleryItems = await CoreApiClient.galleryItems();
      dispatch(createAction(SET_GALLERY_ITEMS)({ galleryItems }));
    } catch (e) { logCatch(e); }
  };
